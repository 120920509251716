import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik,Field  } from 'formik';
import {
  Alert,
  Box,
  Button,
  Divider,
  FormHelperText,
  TextField,
  Link,
  Typography
} from '@material-ui/core';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import firebase from 'lib/firebase';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useRememberMe from '../../../hooks/useRememberMe';
import {config}  from 'config';
import {login } from 'service/service';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from "react-i18next";

const LoginFirebase = (props) => {
  const mounted = useMounted();
  const { t, i18n } = useTranslation();
  const { signInWithEmailAndPassword } = useAuth();
  const { rememberMe,saveAcount,removeAcount } = useRememberMe();

  const getValRememberMe = (remember) => ({
    login: remember.login,
    password: remember.login
  });

   const [valRemember, setValRemember] = useState(getValRememberMe(rememberMe));
   const [showPassword, setShowPassword] = useState(false);
   const [allow, setAllow] = useState(false);

   useEffect(() => {
     console.log('rememberMe');
     setAllow(true);
   }, [rememberMe]);

   const responseGoogle = (response) => {
     console.log(response);
   }

   const signInWithGoogle = () => {
     console.log('signInWithGoogle');
     const provider = new firebase.auth.GoogleAuthProvider();
   //  console.log(provider);
     return firebase.auth().signInWithPopup(provider);
   };


  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
      console.log('show ' + showPassword);
      setShowPassword(!showPassword);
  };

  const afterGetToken = (user) => {
      console.log('token ' + user);
  };

  // <Box
  //   sx={{
  //     alignItems: 'center',
  //     display: 'flex',
  //     mt: 2
  //   }}
  // >
  //   <Box sx={{ flexGrow: 1 }}>
  //     <Divider orientation="horizontal" />
  //   </Box>
  //   <Typography
  //     color="textSecondary"
  //     sx={{ m: 2 }}
  //     variant="body1"
  //   >
  //     ИЛИ
  //   </Typography>
  //   <Box sx={{ flexGrow: 1 }}>
  //     <Divider orientation="horizontal" />
  //   </Box>
  // </Box>

  return (
    <div {...props}>
    <Helmet>
      <title>{t('entrance.garageIn')} </title>
      <meta property="description" content={t('entrance.entranceIn')} />
    </Helmet>
    { allow &&
      <Formik
        initialValues={{
          login: rememberMe && rememberMe.login ? rememberMe.login : '',
          password: rememberMe && rememberMe.password ? rememberMe.password : '',
          remember:true,
          submit: null,
        }}
        validationSchema={Yup
          .object()
          .shape({
            login: Yup
              .string()
              .max(255)
              .required(t('entrance.requiredPhone')),
            password: Yup
              .string()
              .max(255)
              .required(t('entrance.requiredPassword'))
          })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            let result = await login(values.login, values.password);
            if(result!=='fail'){
              if (mounted.current) {
                setStatus({ success: true });
                setSubmitting(true);
              }
            //   console.log('remember ' + values.remember);
            //   if (values.remember === true) {
            //       saveAcount({login: values.login, password: values.password});
            // } else {
                  removeAcount();
            // }
          //  console.log(" rememberMe login " + (values ? values.login : ' ') + " password " + (values ? values.password : ' '));
            document.getElementById('redirect').click();
          } else{
              setStatus({ success: false });
              setSubmitting(true);
          }


          } catch (err) {
            console.error(err);
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values,setFieldValue  }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
          <Link
          id='redirect'
            href={`${config.baseUrl}/admin/profile`}
          />


            <Box sx={{ mt: 2 }}>
            </Box>



            <TextField
              error={Boolean(touched.login && errors.login)}
              fullWidth
              helperText={touched.login && errors.login}
              label={t('entrance.phone')}
              margin="normal"
              defaultValue={rememberMe && rememberMe.login ? rememberMe.login : ''}
              name="login"
              onBlur={handleBlur}
              onChange={handleChange}
              type="login"
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label={t('admin.password')}
              defaultValue={rememberMe && rememberMe.password ? rememberMe.password : ''}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={showPassword ?  "text" : "password"}
              variant="outlined"
              InputProps={{
               endAdornment:
               <>
               <IconButton
                  aria-label={t('admin.peekPassword')}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                {  showPassword ?  <Visibility /> : <VisibilityOff />}
                </IconButton>
                </>
                ,
               }}
            />

            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              >
                {t('entrance.entranceIn')}
              </Button>
            </Box>
          </form>
        )}

      </Formik>
    }


    </div>
  );
};

// <FormControlLabel
//  error={Boolean(touched.remember && errors.remember)}
//  helperText={touched.remember && errors.remember}
//  checked={values.remember}
//      onChange={() => setFieldValue("remember", !values.remember)}
//      control={<Checkbox />}
//   label={t('entrance.rememberMe')}
// />

export default LoginFirebase;
