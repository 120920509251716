import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {config}  from 'config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';

import { connect } from 'react-redux';
import {SuccessSnackbar, ErrorSnackbar,InfoSnackbar,WarningSnackbar} from 'comp/Snackbar';
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from 'ut/jwt';
import translationRu from "locales/translationRu.json";

const useStyles = makeStyles(() => ({
  root: {}
}));

export function translate(param,id){
  let objectJson = null;
  console.log('translate');
  try {
    if(param){
      objectJson = translationRu;
      let arrParam = param.split('.');
      arrParam.forEach(function(name, i) {
      objectJson = objectJson[name];
      });
    if(id){
      objectJson = objectJson[id];
    }}
} catch (err) {
  objectJson = null;
  console.log('no element to translate ' + param + " " + id);
}
  return objectJson;
}

export function failedToFetch(error){
  if(error && (error === 'Failed to fetch' || error.message === 'Failed to fetch')){
    console.log("Cервер недоступен!");
    setItem('message', "Cервер недоступен! Приносим свои извенения. ");
    setItem('info', true);
    setItem('status', 10);
    setItem('redirect', true);
  }
}

export function handleResponse(response){
   return response.text().then(text => {
       const data = text && JSON.parse(text);
      // console.log(data);
       if (!response.ok) {
           if (response.status === 500 || response.status === 400) {
               setItem('message', "Произошла ошибка. Приносим свои извенения!");
               setItem('status', response.status);
               setItem('error', true);
           } else if(response.status === 401){
             console.log("Cервер недоступен!");
             setItem('message', "Произошла ошибка! Сервер недоступен! Приносим свои извенения. ");
             setItem('error', true);
             setItem('status', 10);
             setItem('redirect', true);
           }
           const error = (data && data.message) || response.statusText || data.event;
           return Promise.reject(error);
       }

       return data;
   });
}

export function setMessage(message){
  setItem('message', message);
}

export function setStatus(status){
  setItem('status', status);
}

export function setItem(name,object){
  localStorage.setItem(name, JSON.stringify(object));
}

export function renderRedirect(redirect){
  const redirectValue = getItem('redirectValue');
   if (getItem('redirect')) {
     setItem('redirect',false);
      switch(getItem('status')){
        case '401':
        return <Redirect to='/errors/error-401' />
        break;
        case '404':
        return <Redirect to='/errors/error-404' />
        break;
        case '500':
        return <Redirect to='/errors/error-500' />
        break;
      }
     return <Redirect to='/' />
   }
 }

export function getItem(name){
  switch (name) {
    case 'redirect':
    return (localStorage.getItem(name) === 'true');
    case 'success':
    return (localStorage.getItem(name) === 'true' );
    case 'info':
    return (localStorage.getItem(name) === 'true');
    case 'error':
    return (localStorage.getItem(name) === 'true');
    case 'warning':
    return (localStorage.getItem(name) === 'true');
    case 'message':
    return localStorage.getItem(name)!=null && localStorage.getItem(name)!='null' ? localStorage.getItem(name):'';
    case 'status':
    return localStorage.getItem(name);
    default:
    return JSON.parse(localStorage.getItem(name));
  }
}

export function initRepositoryEx(){
    setItem('dataEx', null);
    setItem('pagination', null);
}

export function initRepository(){
  setItem('message', null);
  setItem('status', null);
  setItem('info', null);
  setItem('redirect', false);
  setItem('data', null);
  setItem('dataEx', null);
  setItem('pagination', null);
  setItem('success', false);
  setItem('info', false);
  setItem('error', false);
  setItem('warning', false);
}

export function setPagination(data){
  console.log('setPagination');
  var pagination = {
    "last" : data.page.last ? data.page.last: null,
    "totalPages" : data.page.totalPages ? data.page.totalPages : null,
    "totalElements" : data.page.totalElements ? data.page.totalElements : null,
    "size" : data.page.size ? data.page.size : null,
    "number" : data.page.number ? data.page.number : null,
    "numberOfElements" : data.page.numberOfElements ? data.page.numberOfElements: null,
    "sort" : {
      "direction" : data.sort && data.sort.length>0 && data.sort[0].direction ? data.sort[0].direction : null,
      "property" : data.sort && data.sort.length>0 && data.sort[0].property ? data.sort[0].property : null,
      "ignoreCase" : data.sort && data.sort.length>0 && data.sort[0].ignoreCase ? data.sort[0].ignoreCase : null,
      "nullHandling" : data.sort && data.sort.length>0 && data.sort[0].nullHandling ? data.sort[0].nullHandling : null,
      "ascending" : data.sort && data.sort.length>0 && data.sort[0].ascending ? data.sort[0].ascending : null,
      "descending" : data.sort && data.sort.length>0 && data.sort[0].descending ? data.sort[0].descending : null
    },
    "first" : data.first
  }
  setItem('pagination', pagination);
return pagination;
}


export async function getProfileToken() {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getProfileToken');
      console.log(user);
      console.log(`${config.apiUrl}/api/organization/profile/info`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + user.token
                  }
        };

        return fetch(`${config.apiUrl}/api/organization/profile/info`, requestOptions)
            .then(handleResponse)
            .then(profile => {
                window.localStorage.setItem('profile', JSON.stringify(profile));
                  resolve(profile);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getProfileToken error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getProfileToken error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function login( phone, password ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrl}/api/auth/organization/sign-in`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                                  username: phone,
                                  password: password
                    })
        };

        return fetch(`${config.apiUrl}/api/auth/organization/sign-in`, requestOptions)
            .then(handleResponse)
            .then(user => {

                // login successful if there's a jwt token in the respons
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                  //  user.roles = ["ROLE_ADMIN"];
                    sessionStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('user', JSON.stringify(user));
                    resolve(user);
                return 'sucsess';
            }).catch(response => {
              console.log(response);
                if(response === 'forbidden.authorization.invalid-credentials'){
                toast.success('Извините! Вы ввели не верный телефон и пароль! ',{duration: 6000},);
              }else{
                toast.success('Извините! Что-то не так! Мы не можем дать Вам доступ! Попробуйте позже.',{duration: 6000},);
              }
                resolve('fail');

              failedToFetch(response);
              return 'fail';
            });
    } catch (err) {
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function logout( ) {
  const user = getItem('user');
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrl}/api/v1/garage/authorization/logout`);
      if(user!== null && user!==undefined && user!=='undefined'){
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Access-Token': user.token
                    }
        };

        return fetch(`${config.apiUrl}/api/v1/garage/authorization/logout`, requestOptions)
            .then(handleResponse)
            .then(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('user', null);
                    localStorage.setItem('user', null);
                    resolve('success');
                return 'sucsess';
            }).catch(response => {
              console.log(response);
              //  toast.error('Извините! Что-то пошло не так! Не можем достуаться до сервера',{duration: 6000},);
              resolve('fail');
              failedToFetch(response);
              return 'fail';
            });
          }
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }

  });
}

export async function me(accessToken) {
  return new Promise((resolve, reject) => {
    try {
      // Decode access token
      const { userId } = decode(accessToken);

      resolve({
        id: user.id,
        avatar: user.avatar,
        email: user.email,
        name: user.name,
        phone: user.phone
      });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Internal server error'));
    }
  });
}


export async function changePhone(phone) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log(`${config.apiUrl}/api/v1/garage/profile/change_phone`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Access-Token': user.token
                    },
                    body: JSON.stringify({
                    newPhone: phone,
                    })
        };

        return fetch(`${config.apiUrl}/api/v1/garage/profile/change_phone`, requestOptions)
            .then(handleResponse)
            .then(data => {
              if(data === 'unprocessable.registration.sms_wait'){
                toast.success('К сожалению у Вас закончились попытки!',{duration: 6000},);
                resolve('fail');
              }
                resolve(data);
                return 'success';
            }).catch(response => {
              console.log(response);
              if(response==='unprocessable.garage.already-registered'){
                toast.success('Такой номер у нас уже зарегистрирован!',{duration: 6000},);
                resolve('existed_user');
              }else if(response==='not-found.garage' || response==='unprocessable.garage.not-required'){
                toast.success('Номера телефона, на который отправляется смс нет в нашей базе!',{duration: 6000},);
                resolve('fail');
              }else  if(response === 'unprocessable.registration.sms_wait'){
                toast.success('К сожалению у Вас закончились попытки! Подождите и поробуйте заново!',{duration: 6000},);
                resolve('fail');
              }else{
                toast.error('Извините! Что-то пошло не так при регистрации!',{duration: 6000},);
                resolve('fail');
              }
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {

      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function smsConfirm( phone, code ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrl}/api/v1/garage/authorization/registration/confirm_sms`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                                  phone: phone,
                                  code: code
                    })
        };

        return fetch(`${config.apiUrl}/api/v1/garage/authorization/registration/confirm_sms`, requestOptions)
            .then(handleResponse)
            .then(user => {
              console.log(user);
                // login successful if there's a jwt token in the response
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('user', JSON.stringify(user));
                    resolve(user);
                }else{
                  resolve('no_user');
                }
                return 'sucsess';
            }).catch(response => {
              console.log(response);
              if(response==='unprocessable.garage.already-registered'){
                toast.success('Такой номер уже зарегистрирован у нас',{duration: 6000},);
                resolve('existed_user');
              }else if(response==='unprocessable.registration.require-confirm' || response==='unprocessable.garage.require-confirm'){
                toast.success('Ваш sms не подтверждён!',{duration: 6000},);
                resolve('fail');
              }else if(response==='not-found.garage' || response==='unprocessable.garage.not-required'){
                toast.success('Номера телефона, на который отправляется смс нет в нашей базе! Пройдите регистрацию',{duration: 6000},);
                resolve('fail');
              }else if(response==='unprocessable.registration.incorrect-code' || response==='unprocessable.garage.incorrect-code'){
                toast.success('Введён не верный смс! Введите другой код!',{duration: 6000},);
                resolve('fail');
              }else{
                toast.error('Извините! Что-то пошло не так при регистрации! Попробуйте еще раз через какое-то время',{duration: 6000},);
                resolve('fail');
              }
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function smsResend( phone ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrl}/api/v1/garage/authorization/sms/resend`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                                  phone: phone,
                                  appType: 0
                    })
        };

        return fetch(`${config.apiUrl}/api/v1/garage/authorization/sms/resend`, requestOptions)
            .then(handleResponse)
            .then(sms => {
              console.log(sms);
                  resolve(sms);
                return 'sucsess';
            }).catch(response => {
              console.log(response);
              if(response==='not-found.garage'){
                toast.success('Номера телефона, на который отправляется смс нет в базе. Пройдите регистрацию!',{duration: 6000},);
                resolve('existed_user');
              }else if(response==='unprocessable.garage.not-required'){
                toast.success('Ваш телефон уже подтверждён! Пожалуйста воспользуйтесь формой авторизации для входа!',{duration: 6000},);
                resolve('fail');
              }else if(response==='unprocessable.registration.sms_timeout'){
                toast.success('Смс уже посылалась недавно, повторите попытку через минуту!',{duration: 6000},);
                resolve('fail');
              }else if(response==='unprocessable.registration.attempt_limit'){
                toast.success('Превышено количество запросов на отправку смс! Подождите и попробуйте снова отправить sms код',{duration: 6000},);
                resolve('fail');
              }else{
                toast.success('Извините! Что-то пошло не так! Не можем запросить sms для Вас',{duration: 6000},);
                resolve('fail');
              }
              resolve('fail');
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function uploadAvatar(image){
 const user = getItem('user');
  return new Promise((resolve, reject) => {
    try {
      console.log('uploadAvatar');
      console.log(`${config.apiUrl}/api/garage/images/upload/GARAGE_AVATAR`);

        const requestOptions = {
            crossDomain:false,
            method: 'POST',
            headers: {'Access-Token': user.token},
            body: image
        };

        return  fetch(`${config.apiUrl}/api/garage/images/upload/GARAGE_AVATAR`, requestOptions)
            .then(handleResponse)
            .then(result => {
              setItem('success',true);
              resolve(result);
              return result;
            }).catch(response => {
              failedToFetch(response);
              console.log(response);
              resolve('fail');
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Internal server error'));
    }
  });
}

export async function addAvatar(id){
 const user = getItem('user');
  return new Promise((resolve, reject) => {
    try {
      console.log('addAvatar');
      console.log(`${config.apiUrl}/api/v1/garage/profile/avatar/?` + 'avatarId=' + id);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Access-Token': user.token
                    }
        };
        return  fetch(`${config.apiUrl}/api/v1/garage/profile/avatar/?` + 'avatarId=' + id, requestOptions)
            .then(handleResponse)
            .then(result => {
              toast.success('Спасибо! Ваше фото добавлено! ',{duration: 6000},);
              resolve(result);
              return result;
            }).catch(response => {
              failedToFetch(response);
              resolve('fail');
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Internal server error'));
    }
  });
}



export  async function checkRecognizeCarPoto(image,angle,degre){
 const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
      console.log('checkManPoto');
      console.log(`${config.apiUrlAi}/recognize/car/angle/json?angle=` + `${angle}` + `&degre=` + `${degre}`);

        const requestOptions = {
            method: 'POST',
            body:image
        };

        return  fetch(`${config.apiUrlAi}/recognize/car/angle/json?angle=`+  `${angle}` + `&degre=` + `${degre}`, requestOptions)
            .then(handleResponse)
            .then(result => {
              if(result.result==1){
                   resolve(result);
                   setItem('success',true);
              }else{
                   resolve(result);
                   setItem('warning',true);
              }
              console.log(result);
              return result;
            }).catch(response => {
              console.log(response);
              resolve('fail');
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
    }
    });
}



export async function updateInfoUserProfile(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/profile/updateContactInfo`);
       const requestOptions = {
           method: 'POST',
           crossDomain:true,
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/profile/updateContactInfo`, requestOptions)
           .then(handleResponse)
           .then(result => {
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('updateInfoUserProfile');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function updateBaseInfoProfile(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/profile/updateBaseInfo`);
       const requestOptions = {
           method: 'POST',
           crossDomain:true,
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/profile/updateBaseInfo`, requestOptions)
           .then(handleResponse)
           .then(result => {
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('updateBaseInfo');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function updateBankInfo(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/profile/updateBankInfo`);
       const requestOptions = {
           method: 'POST',
           crossDomain:true,
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/profile/updateBankInfo`, requestOptions)
           .then(handleResponse)
           .then(result => {
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('updateBankInfo');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });
}

export async function changePassword(password){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/v1/garage/profile/change_password`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Access-Token': user.token
                   },
           body: JSON.stringify({
            newPassword: password
          })
       };
       return  fetch(`${config.apiUrl}/api/v1/garage/profile/change_password`, requestOptions)
           .then(handleResponse)
           .then(result => {
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('changePassword');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}


export async function addCar(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/car/add`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/car/add`, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('addCar');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             if(response === 'unprocessable.car.already_exists'){
               resolve(response);
             }else{
              resolve('fail');
             }
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}


export async function editCar(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/car/updateCar`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/car/updateCar`, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('updateCar');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             if(response === 'unprocessable.car.already_exists'){
               resolve(response);
             }else{
              resolve('fail');
             }
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function sendToRegistration(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/car/sendToRegistration/`);
       const requestOptions = {
           method: 'POST',
           crossDomain:true,
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/car/sendToRegistration`, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('sendToRegistration');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             if(response === 'unprocessable.car.already_exists'){
               resolve(response);
             }else{
              resolve('fail');
             }
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function addDriver(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/driver/add`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/driver/add`, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('addDriver');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             if(response === 'unprocessable.driver.already_exists'){
               resolve(response);
             }else{
              resolve('fail');
             }
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function changeDriverCar(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/driver/changeDriverCar`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/driver/changeDriverCar`, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('changeDriverCar');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             if(response === 'not-found.car'){
               resolve(response);
               return 'not-found.car';
             }else{
              resolve('fail');
             }
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function getDrivers(page,size,sort,order,driverStatus=null,search = null,searchOption=null,driverId=null,organizationId=null,carId=null) {
  return new Promise((resolve, reject) => {
    try {

      const user = getItem('user');
      var sorting = "id";
      switch (sort) {
        case 'id':
          sorting = "id";
          break;
        case 'organizationStatus':
          sorting = "organizationStatus";
        break;
        case 'kassaStatus':
          sorting = "kassaStatus";
        break;
        case 'name':
          sorting = "surname,name,fatherName";
        break;
        case 'driverLicence':
          sorting = "driverLicence";
        break;
        case 'drivingExperienceStart':
          sorting = "drivingExperienceStart";
        break;
        default:
          sorting = "id";
      }
      sorting+= (order==='desc' ? "," + "desc": "" );

      const searchStr = search!=='undefined' && search!== null && search!==undefined && search!=='' && searchOption!=null && searchOption === 'driver' ? "&driverQuery=" + search  : "";
      const searchCarStr = search!=='undefined' && search!== null && search!==undefined && search!=='' && searchOption!=null && searchOption === 'car' ? "&carQuery=" + search  : "";
      const statusStr = driverStatus!=='undefined' && driverStatus!== null && driverStatus!==undefined && driverStatus!=='' ? "&driverStatus=" + driverStatus : "";

      console.log(`${config.apiUrl}/api/organization/driver/list` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr + searchCarStr);
        const requestOptions = {
          method: 'POST',
          crossDomain:true,
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + user.token
                  }
        };

        return fetch(`${config.apiUrl}/api/organization/driver/list` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr + searchCarStr, requestOptions)
            .then(handleResponse)
            .then(drivers => {
              setItem('pagination', drivers);
              setItem('data', drivers.content);
              resolve(drivers);
            }).catch(response => {
              resolve('fail');
              console.log('getDrivers error');
              console.log(response);
              failedToFetch(response);
            });;
    } catch (err) {
      console.log('getCars error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getDriverById(id) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getDriverById');
      console.log(user);
      console.log(`${config.apiUrl}/api/organization/driver/info?driverId=` + id);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + user.token
                  }
        };

        return fetch(`${config.apiUrl}/api/organization/driver/info?driverId=` + id, requestOptions)
            .then(handleResponse)
            .then(driver => {
                resolve(driver);
            }).catch(response => {
              resolve('fail');
              console.log('getDriverById error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getDriverById error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function editDriver(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrl}/api/organization/driver/updateDriver`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Authorization': 'Bearer ' + user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrl}/api/organization/driver/updateDriver`, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('updateDriver');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
              resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function getApis(page,size,sort,order,kassaStatus=null,search = null,kassaId=null,organizationId=null,carId=null) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      var sorting = "id";
      switch (sort) {
        case 'id':
          sorting = "id";
          break;
        case 'regNumber':
          sorting = "car.regNumber";
        break;
        case 'kassaStatus':
          sorting = "car.kassaStatus";
        break;
        case 'paymentDueDate':
          sorting = "paymentDueDate";
        break;
        default:
          sorting = "id";
      }
      sorting+= (order==='desc' ? "," + "desc": "" );

      const searchStr = search!=='undefined' && search!== null && search!==undefined && search!=='' ? "&carQuery=" + search : "";
      const statusStr = kassaStatus!=='undefined' && kassaStatus!== null && kassaStatus!==undefined && kassaStatus!=='' ? "&searchSpecification.kassaStatus=" + kassaStatus : "";

      console.log(`${config.apiUrl}/api/organization/kassa/list` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr);
        const requestOptions = {
          method: 'POST',
          crossDomain:true,
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + user.token
                  }
        };

        return fetch(`${config.apiUrl}/api/organization/kassa/list` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr, requestOptions)
            .then(handleResponse)
            .then(apis => {
              setItem('pagination', apis);
              setItem('data', apis.content);
              resolve(apis);
            }).catch(response => {
              resolve('fail');
              console.log('getApis error');
              console.log(response);
              failedToFetch(response);
            });;
    } catch (err) {
      console.log('getCars error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getApi(id) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getDriverById');
      console.log(user);
      console.log(`${config.apiUrl}/api/organization/kassa/info?kassaId=` + id);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + user.token
                  }
        };

        return fetch(`${config.apiUrl}/api/organization/kassa/info?kassaId=` + id, requestOptions)
            .then(handleResponse)
            .then(kassa => {
                resolve(kassa);
            }).catch(response => {
              resolve('fail');
              console.log('getApi error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getApi error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getCarById(id) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getCarById');
      console.log(user);
      console.log(`${config.apiUrl}/api/organization/car/info?carId=` + id);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + user.token
                  }
        };

        return fetch(`${config.apiUrl}/api/organization/car/info?carId=` + id, requestOptions)
            .then(handleResponse)
            .then(car => {
                resolve(car);
            }).catch(response => {
              resolve('fail');
              console.log('getCarById error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getCarById error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getCars(page,size,sort,order,kassaStatus=null,search = null,carId=null,organizationId=null,regNumber=null,year=null) {
  return new Promise((resolve, reject) => {
    try {

      const user = getItem('user');
      var sorting = "id";
      switch (sort) {
        case 'id':
          sorting = "id";
          break;
        case 'regNumber':
          sorting = "regNumber";
        break;
        case 'kassaStatus':
          sorting = "kassaStatus";
        break;
        case 'automaker':
          sorting = "automaker";
        case 'model':
          sorting = "model";
        break;
        case 'year':
          sorting = "year";
        break;
        default:
          sorting = "id";
      }
      sorting+= (order==='desc' ? "," + "desc": "" );

      const searchStr = search!=='undefined' && search!== null && search!==undefined && search!=='' ? "&carQuery=" + search : "";
      const statusStr = kassaStatus!=='undefined' && kassaStatus!== null && kassaStatus!==undefined && kassaStatus!=='' ? "&kassaStatus=" + kassaStatus : "";

      console.log(`${config.apiUrl}/api/organization/car/list` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr);
        const requestOptions = {
          method: 'POST',
          crossDomain:true,
          headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + user.token
                  }
        };

        return fetch(`${config.apiUrl}/api/organization/car/list` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr, requestOptions)
            .then(handleResponse)
            .then(cars => {
              setItem('pagination', cars);
              setItem('data', cars.content);
              resolve(cars);
            }).catch(response => {
              resolve('fail');
              console.log('getCars error');
              console.log(response);
              failedToFetch(response);
            });;
    } catch (err) {
      console.log('getCars error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}




////////////////////////////КОНЕЦ//////////////////////////////////////




class Service extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    serviceId: null,
    change:0,
    success:false,
    info:false,
    error:false,
    warning:false
  };

  this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  this.handleSnackbarInfoClose = this.handleSnackbarInfoClose.bind(this);
  this.handleSnackbarErrorClose = this.handleSnackbarErrorClose.bind(this);
  this.handleSnackbarWarningClose = this.handleSnackbarWarningClose.bind(this);
  this.setChange = this.setChange.bind(this);


  var timer = setInterval(function () {
    if(getItem('redirect')){
      this.setChange();

    }
    setTimeout(() => {
    this.setChange();
    }, 5000);

    if(getItem('error')){
      this.setChange();
      setTimeout(() => {
      setItem('error',false);
      this.handleSnackbarErrorClose();
      this.setChange();
    }, 5000);
    }
    if(getItem('warning')){
      this.setChange();
      setTimeout(() => {
      setItem('warning',false);
      this.handleSnackbarWarningClose();
      this.setChange();
    }, 5000);
    }
    if(getItem('info')){
      this.setChange();
      setTimeout(() => {
      setItem('info',false);
      this.handleSnackbarInfoClose();
      this.setChange();
    }, 5000);
    }
    if(getItem('success')){
      this.setChange();
      setTimeout(() => {
      setItem('success',false);
      this.handleSnackbarClose();
    //  this.setChange();
    }, 5000);
    }

  }.bind(this), 3000);
}

setChange(){
  this.state.change+=1;
  this.setState({ change: this.state.change });
}

  handleSnackbarClose(){
    setItem('success', false);
  };

handleSnackbarInfoClose(){
    setItem('info', false);
  };
handleSnackbarErrorClose(){
    setItem('error', false);
  };
 handleSnackbarWarningClose(){
    setItem('warning', false);
  };

  componentDidMount(){
   this.refresher = setInterval(1000, () => this.forceUpdate())
 }

  componentWillUnmount () {
     clearInterval(this.refresher)
  }



  render() {
    const { classes } = this.props;
    return (
    <div>
    {renderRedirect()}
      <SuccessSnackbar
        onClose={this.handleSnackbarClose}
        open={(getItem('success') )}
        message = {getItem('message')}
      />
      <InfoSnackbar
        onClose={this.handleSnackbarInfoClose}
        open={(getItem('info') )}
        message = {getItem('message')}
      />
      <ErrorSnackbar
        onClose={this.handleSnackbarErrorClose}
        open={(getItem('error') )}
        message = {getItem('message')}
      />
      <WarningSnackbar
        onClose={this.handleSnackbarWarningClose}
        open={(getItem('warning'))}
        message = {getItem('message')}
      />
    </div>
  );
}
};

Service.propTypes = {
  className: PropTypes.string,
  pushMessage: PropTypes.func.isRequired
};

export default connect (
  (state, props) => ({
    authentication: state.authentication,
    queue: state.queue
  }),
  dispatch => ({
    pushMessage: (queue) => {
    dispatch({ type: 'SAVE_QUEUE', payload: queue })
  },
  checkData: (queue) => {
  dispatch({ type: 'CHECK_DATA', payload: queue })
  }
  })
)(Service);
