import { Suspense,lazy,useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import {  ThemeProvider } from '@material-ui/core';
import './i18n';
import { gtmConfig } from './config';
import { config } from 'config';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import { FirstMessageProvider } from 'contexts/FirstMessageContext';
import gtm from './lib/gtm';
import routes from 'template/routes';
import { createCustomTheme } from './theme';
import { history } from './authorization/_helpers';
import { renderRoutes } from 'react-router-config';
import AOS from 'aos';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'aos/dist/aos.css';
import { passiveSupport } from 'passive-events-support/src/utils';
import Snowfall from 'react-snowfall';
import { THEMES } from 'constants';
import LoadingScreen from 'comp/LoadingScreen';
import {
  useWindowWidth,
  useWindowHeight,
} from '@react-hook/window-size';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const CssBaseline = Loadable(lazy(() => import('@material-ui/core/CssBaseline' /* webpackChunkName: "CssBaseline" */)));
const ScrollReset = Loadable(lazy(() => import('./template/components/ScrollReset' /* webpackChunkName: "ScrollReset" */)));
const GoogleAnalytics = Loadable(lazy(() => import('./template/components/GoogleAnalytics' /* webpackChunkName: "GoogleAnalytics" */)));
const CookiesNotification = Loadable(lazy(() => import('./template/components/CookiesNotification' /* webpackChunkName: "CookiesNotification" */)));
const RTL = Loadable(lazy(() => import('./components/RTL' /* webpackChunkName: "RTL" */)));



 const snowflake1 = document.createElement('img')
 snowflake1.src = `${config.baseUrl}/images/svg/flake1.svg`;
 const snowflake2 = document.createElement('img')
 snowflake2.src = `${config.baseUrl}/images/svg/flake2.svg`;
 const snowflake3 = document.createElement('img')
 snowflake3.src = `${config.baseUrl}/images/svg/flake3.svg`;
 const snowflake4 = document.createElement('img');
 snowflake4.src = `${config.baseUrl}/images/svg/flake4.svg`;
 const snowflake5 = document.createElement('img')
 snowflake5.src = `${config.baseUrl}/images/svg/flake5.svg`;

const images = [ snowflake1,snowflake2,snowflake3,snowflake4,snowflake5];


const App = () => {
  const { settings } = useSettings();
  const auth = useAuth();
  const onlyWidth = useWindowWidth();
  useScrollReset();

  passiveSupport({
    debug: false,
    listeners: [
      {
        element: 'div.some-element',
        event: 'touchstart',
        prevented: true
      }
    ]
  })

  useEffect(() => {
    gtm.initialize(gtmConfig);
    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    });

    const  handleSwitchSnow = () =>  {
      if (settings.snowfall) {
          setValues({
            ...values,
            ['snowfall']: false
          });
          saveSettings(values);
          return false;
        }
      setValues({
        ...values,
        ['snowfall']: true
      });
     settings.snowfall = true;
      saveSettings(values);
      return true;
    }


  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    snowfall : settings.snowfall,
    forecasting: settings.forecasting,
    posting:settings.posting,
    plateHidding:settings.plateHidding
  });


  return (
    <ThemeProvider theme={theme}>
    <Router history={history}>
      <RTL direction={settings.direction}>
      <FirstMessageProvider>
      <ScrollReset />
      <GoogleAnalytics />
      <CssBaseline />
      <Toaster position="top-center" />
        {renderRoutes(routes)}
      </FirstMessageProvider>
      </RTL>
      </Router>

    </ThemeProvider>
  );
};


// {(settings.theme === THEMES.DARK || settings.theme === THEMES.NATURE) && settings.snowfall &&
//   <Snowfall color={ '#dee4fd'}
//   snowflakeCount={onlyWidth<=490 ? 5 : 20} style={{
//   position: 'fixed',
//   width: '100vw',
//   height: '100vh',
//   zIndex:'110'
//   }}/>
// }
// {(settings.theme === THEMES.LIGHT ) && settings.snowfall &&
//   <Snowfall color={'#00bcd4'}
//   images={images}
//   radius = {[15,15]}
//   snowflakeCount={onlyWidth<=490 ? 5 : 20} style={{
//   position: 'fixed',
//   width: '100vw',
//   height: '100vh',
//   zIndex:'110'
//   }}/>
// }
// { settings.snowfall &&
// <Snowfall  color={settings.theme === THEMES.DARK || settings.theme === THEMES.NATURE ? '#dee4fd' : '#00bcd4'}
// images={images}
// snowflakeCount={onlyWidth<=490 ? 5 : 20} style={{
// position: 'fixed',
// width: '100vw',
// height: '100vh',
// zIndex:'1000'
// }}/>
// }


export default App;
