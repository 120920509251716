import { useEffect,useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from '../pages/authentication/Login';
import {getProfileToken} from 'service/service';
import toast from 'react-hot-toast';
import {Link} from '@material-ui/core';
import {config}  from 'config';

const AuthGuard = (props) => {
  const { children,redir } = props;
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  const [user, setUser] = useState(null);
  const [redirect, setRedirect] = useState(redir);

  useEffect(() => {
    setTimeout(async () => {
      let user = JSON.parse(localStorage.getItem('user'));
      user = user ? user : JSON.parse(sessionStorage.getItem('user'));
      if(user!== null && user!==undefined && user!=='undefined'){
        setUser(user);
      }else{
        setUser({token:null});
        setRedirect(true);
      }
    }, 2000);
  setInterval(async () => {
    let result = await getProfileToken();
    console.log('getProfileToken');
    console.log(result);
    if(result === 'fail'){
      sessionStorage.setItem('user', null);
      localStorage.setItem('user', null);
      toast.success('outOfTime',{duration: 6000},);
      setRedirect(true);
    }
  }, 100000);

   }, []);


  if (redirect ) {
    setTimeout(async () => {
      document.getElementById('redirect').click();
    }, 100);
      return  <Link id='redirect' href={`${config.baseUrl}/admin/login`}/>
  }


  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
  redir: PropTypes.boolean,
};

export default AuthGuard;
