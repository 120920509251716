import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {config}  from 'config';
import { useTranslation } from 'react-i18next';

const initialLanguage = {
        language: `${config.language}`
};

export const restoreLanguage = () => {
  let language = null;
  try {
    const storedData = window.localStorage.getItem('language');

    if (storedData) {
      language = JSON.parse(storedData);
    } else {
      language = {
        language: `${config.language}`
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return language;
};

export const storeLanguage = (language) => {
  window.localStorage.setItem('language', JSON.stringify(language));
};

const LanguageContext = createContext({
  language: initialLanguage,
  saveLanguage: () => {},
  removeLanguage: () => {}
});

export const LanguageProvider = (props) => {
  const { children } = props;
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    const updateLanguage = restoreLanguage();
    if (updateLanguage) {
      setLanguage(updateLanguage);
      if(updateLanguage.language){
      i18n.changeLanguage(updateLanguage.language);
    }else{
      i18n.changeLanguage(updateLanguage);
    }
    }
  }, []);

  const saveLanguage = (updatedLanguage) => {
    console.log('saveLanguage');
    i18n.changeLanguage(updatedLanguage.language);
    setLanguage(updatedLanguage);
    storeLanguage(updatedLanguage);
    console.log(language);
  };

  const removeLanguage = () => {
    setLanguage({
      language:  `${config.language}`
    });
    storeLanguage({
      language:  `${config.language}`
    });
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        saveLanguage,
        removeLanguage
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const LanguageConsumer = LanguageContext.Consumer;

export default LanguageContext;
